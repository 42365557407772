import React from "react";
import "./about.scss";
import { PiHouseLine } from "react-icons/pi";
import { BsCreditCard } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const about = () => {
  const waabout = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285880888900&text=Halo%20Agung,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20(about)%20https://marketingfamiliaurban.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const wabrosur = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285880888900&text=Halo%20Agung,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20(brosur)%20https://marketingfamiliaurban.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="about" className="about">
      <div className="container-about">
        <div className="header-about">
          <div className="judul-about"> Familia Urban</div>
          <div className="subjudul-about">Bekasi, Jawa Barat</div>
          <div className="sec-about">
            <div className="home-about">
              <div className="icon-home-about">
                <PiHouseLine />
              </div>
              <div className="ket-home">
                <div className="judul-home-about">Tipe Propety</div>
                <div className="subjudul-home-about">
                  Rumah Ready Dan Indent
                </div>
              </div>
              <div className="button-home-about">
                <button className="brosur" onClick={wabrosur}>
                  Brosur
                </button>
              </div>
            </div>
            <div className="pembayaran-about">
              <div className="icon-pembayaran-about">
                <BsCreditCard />
              </div>
              <div className="ket-pembayaran">
                <div className="judul-pembayaran-about">Jenis Pembayaran</div>
                <div className="subjudul-pembayaran-about">
                  Cash dan KPR 100% Approved
                </div>
              </div>
              <div className="button-pembayaran-about">
                <button className="card" onClick={waabout}>
                  <span className="waicon">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </span>
                  &nbsp; Whatsapp
                </button>
              </div>
            </div>
          </div>
          <div className="about-us">
            <div className="judul-about-us">
              Kawasan Mandiri @Kota Bekasi 176ha
            </div>
            <div className="subjudul-about-us">
              Familia Urban Bekasi, yang berarti "perumahan hijau yang ramah
              anak di Bekasi," dibangun oleh PT Timah Karya Persada Properti di
              Bekasi, Jawa Barat. Family Urban Bekasi menawarkan hunian tapak
              yang terjangkau dengan konsep perumahan hijau yang ramah anak
              dengan taman, koridor ramah lingkungan, jalur lari, kolam renang,
              dan fasilitas lainnya. Selain itu, proyek ini memiliki sistem
              drainase yang baik untuk mencegah banjir dan memiliki keamanan
              yang terjamin dengan CCTV dan keamanan 24 jam.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default about;
