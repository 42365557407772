import React from "react";
import "./footer.scss";
import logo from "../../asset/logo-urbanfam.webp";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-bsd" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h2>Marketing Gallery</h2>
            <h1>Familia Urban Bekasi</h1>
            <p>
              Jl. Mandor Demong, RT.001/RW.005, Mustikasari, Kec. Mustika Jaya,
              Kota Bks, Jawa Barat 17157
            </p>
          </div>
          <div className="contact">Contact Marketing : +6285880888900 </div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">FAMILIA URBAN BEKASI</div>
    </div>
  );
};

export default footer;
